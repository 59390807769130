import React from "react"

import { PageProps } from "gatsby"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import styled from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: space-between;
  width: 960;
`

const MainArea = styled.div`
  margin-right: 3vw;
  text-align: left;
`

const SideBar = styled.div`
  width: 40vw;
`

const IndexPage = (props:PageProps) => (
  <Layout>
    <SEO title="Home" />

    <Container>
      <MainArea>
        <h4>
          Macklin & Co is a Romsey based firm of accountants, established for
          over 25 years. Our clients range from private individuals to limited
          companies.
        </h4>
        <p>Our services include:</p>
        <ul>
          <li> Business start ups</li>
          <li>Self assessment tax</li>
          <li>Book keeping</li>
          <li>VAT</li>
          <li>Payroll and CIS</li>
          <li>Management accounts</li>
          <li>Statutory accounts</li>
          <li>Corporate tax</li>
        </ul>
        <p>
          Please feel free to get in contact with any enquiries and no
          obligation.
        </p>
        <p>Phone: (01794) 511 511</p>
        <p>Email: accounts@macklinandco.co.uk</p>
      </MainArea>
      <SideBar>
        <h4>Our contact details:</h4>
        <p>
          Macklin & Co <br />
          7 Latimer Street
           <br /> Romsey <br /> Hampshire <br /> SO51 8DF
        </p>
        Tel: (01794) 511 511 <br /> Email: accounts@macklinandco.co.uk
      </SideBar>
    </Container>
  </Layout>
)

export default IndexPage
